import { ClassicFooter } from '../footers/ClassicFooter';
import { FlaggedHeader } from '../headers/FlaggedHeader';
import { LandmarkLink } from '@exploration/shared-components';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const FallbackPageLayout = ({ children }: Props) => (
  <>
    <FlaggedHeader flags={{}} />

    <LandmarkLink id="content" href="#content">
      Start of main content
    </LandmarkLink>

    {children}

    <ClassicFooter />
  </>
);
